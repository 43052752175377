import { useMutation, useQueryClient } from "react-query";
import { updatePost } from "../api";

export function useUpdatePost(postId: string | undefined) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (values: any) => updatePost(postId, values),
    onSuccess: (newPost) => {
      // ✅ update detail view directly
      queryClient.setQueryData(["post", postId], newPost);
    },
  });
}
