import { useMutation, useQueryClient } from "react-query";
import { updateDoctor } from "../api";
import { DoctorUserType } from "../api-types";

export function useUpdateDoctor(doctorId: number | undefined) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (values: DoctorUserType) => updateDoctor(doctorId, values),
    onSuccess: async (updatedUser) => {
      await queryClient.setQueryData(["current-user"], updatedUser);
    },
  });
}
