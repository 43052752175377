import { useForm } from "react-hook-form";
import { DoctorUserType } from "../../api-types";
import { getDirtyValues } from "../../helpers/forms/getDirtyValues";

type DoctorProfileFormType = {
  loading: boolean;
  onSubmit: any;
  currentUser: DoctorUserType;
};

export const DoctorProfileForm = ({
  currentUser,
  loading,
  onSubmit,
}: DoctorProfileFormType) => {
  const {
    formState: { errors, isDirty, dirtyFields },
    handleSubmit,
    register,
  } = useForm({
    defaultValues: currentUser,
  });
  const onSubmitDirtyValues = (data: any) => {
    onSubmit(getDirtyValues(dirtyFields, data));
  };

  return (
    <form
      className="mw-300 mx-auto"
      onSubmit={handleSubmit(onSubmitDirtyValues)}
    >
      <div className="form-group mb-4">
        <label htmlFor="officeName">Name</label>
        <input
          className="form-control"
          placeholder="Name"
          id="name"
          {...register("name", { required: true })}
        />
        {errors.name && <span>Name is required</span>}
      </div>
      <div className="form-group mb-4">
        <label htmlFor="phone">Phone Number</label>
        <input
          className="form-control"
          placeholder="Phone Number"
          id="phone"
          {...register("phone", { required: true })}
        />
        {errors.phone && <span>Phone number is required</span>}
      </div>
      <div className="form-group mb-4">
        <label htmlFor="street">Street Address</label>
        <input
          className="form-control"
          placeholder="Street Address"
          id="street"
          {...register("street", { required: true })}
        />
        {errors.street && <span>Street address is required</span>}
      </div>
      <div className="form-group mb-4">
        <label htmlFor="city">City</label>
        <input
          className="form-control"
          placeholder="City"
          id="city"
          {...register("city", { required: true })}
        />
        {errors.city && <span>City is required</span>}
      </div>
      <div className="form-group mb-4">
        <label htmlFor="officeName">State</label>
        <input
          className="form-control"
          placeholder="State"
          id="state"
          {...register("state", { required: true })}
        />
        {errors.state && <span>State is required</span>}
      </div>
      <div className="form-group mb-4">
        <label htmlFor="zipCode">Zipcode</label>
        <input
          className="form-control"
          placeholder="Zipcode of office location"
          id="zipcode"
          {...register("zipCode", { required: true })}
        />
        {errors.zipCode && <span>Zipcode is required</span>}
      </div>
      <div className="form-group mb-4">
        <label htmlFor="zipCode">Florida Chiropractic License Number</label>
        <input
          className="form-control"
          placeholder="Florida Chiropractic License Number"
          id="licenseNumber"
          {...register("licenseNumber", { required: true })}
        />
        {errors.licenseNumber && <span>License number is required</span>}
      </div>
      <div className="form-group mb-4">
        <label htmlFor="zipCode">Malpractice Insurance Number</label>
        <input
          className="form-control"
          placeholder="Malpractice Number"
          id="malpracticeNumber"
          {...register("malpracticeNumber", { required: true })}
        />
        {errors.malpracticeNumber && (
          <span>Malpractice number is required</span>
        )}
      </div>
      <div className="mt-5">
        <button
          disabled={!isDirty || loading}
          type="submit"
          className="btn btn-primary"
        >
          Save
        </button>
      </div>
    </form>
  );
};
