import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import JotFormReact from "jotform-react";

type Props = {
  isShown?: boolean;
  url: string;
};

const JotFormModal = ({ url, isShown }: Props) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  // const handleSubmit = async (values: any) => {
  //   // await create(values);
  //   setShow(false);
  // };

  useEffect(() => {
    if (!isShown) {
      setShow(false);
    }
  }, [isShown]);

  return (
    <div className="modal" style={{ display: "block", position: "initial" }}>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "10vh" }}
      >
        <Button variant="primary" onClick={handleShow}>
          View and Sign Form Here
        </Button>
      </div>

      <Modal centered size="lg" show={show}>
        <Modal.Header>
          <Button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          ></Button>
        </Modal.Header>

        <Modal.Body>
          <JotFormReact formURL={url} onSubmit={handleClose} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default JotFormModal;
