import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { RegisterForm } from "../../components/forms/RegisterForm";
import { useRegisterUser } from "../../hooks/useRegisterUser";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";

// TODO: change this over to export const?
export const Register = () => {
  const navigate = useNavigate();
  const { authUser, register: registerFirebaseUser, setError } = useAuth();
  const { currentUser } = useGetCurrentUser();
  const [loading, setLoading] = useState(false);
  const { mutate: registerUser } = useRegisterUser();

  useEffect(() => {
    if (authUser && currentUser) {
      navigate("/");
    }
  }, [authUser, currentUser, navigate]);

  async function handleFormSubmit(values: any) {
    const { email, password, confirmPassword } = values;

    if (password !== confirmPassword) {
      return setError("Passwords do not match");
    }

    try {
      setError("");
      setLoading(true);

      await registerFirebaseUser(email, password);
      await registerUser(values, { onSuccess: () => navigate("/") });
    } catch (e) {
      setError("Failed to register");
    }

    setLoading(false);
  }

  return (
    <div className="container-fluid text-center">
      <div className="">
        <h2 className="display-3 mb-6 mt-4">Register your account</h2>

        <RegisterForm loading={loading} onSubmit={handleFormSubmit} />

        <div className="mt-5">
          <Link to="/login" className="">
            Already have an account? Login
          </Link>
        </div>
      </div>
    </div>
  );
};
