import React from "react";

const Home = () => {
  return (
    <>
      <div>
        <header className="masthead">
          <div className="container">
            <div className="masthead-subheading">Welcome to CoverDoc</div>
            <div className="masthead-heading text-uppercase">
              We've got you <span className="text-primary">covered</span>
            </div>
          </div>
        </header>
      </div>
    </>
  );
};

export default Home;
