import { useEffect } from "react";
import { useCurrentUser } from "../../contexts/CurrentUserContext";
import { useNavigate } from "react-router-dom";
import PaymentSetupForm from "./PaymentSetupForm";
import { useGetCheckoutPaymentMethods } from "../../hooks/useGetCheckoutPaymentMethods";
import { CreditCardTile } from "../../components/checkout/CreditCardTile";
import { Button } from "react-bootstrap";
import { STRIPE_CUSTOMER_PORTAL_KEY } from "../../constants/constants";

export const PaymentDetails = () => {
  const { currentUser } = useCurrentUser();
  const { data: paymentMethods, isLoading } = useGetCheckoutPaymentMethods();
  const navigate = useNavigate();

  useEffect(() => {
    // TODO: hoist this check out to router level. Do not let doctors access payment details page
    if (currentUser.role === "doctor") {
      navigate("/");
    }
  }, [currentUser.role, navigate]);

  //   const userIsOffice = currentUser?.role === "office";

  return (
    <div className="container-fluid text-center">
      <h1 className="display-2 mb-5">Payment Details</h1>
      {isLoading ? (
        <>Loading...</>
      ) : (
        <div className="mx-auto" style={{ maxWidth: "500px" }}>
          {paymentMethods && paymentMethods.length > 0 ? (
            <>
              <div>Saved Payment Method</div>
              {paymentMethods.map((x) => (
                <CreditCardTile
                  expiry={`${x.card.exp_month}/${x.card.exp_year}`}
                  last4={x.card.last4}
                  type={x.card.brand}
                />
              ))}
              <Button
                className="mt-3"
                href={`https://billing.stripe.com/p/login/${STRIPE_CUSTOMER_PORTAL_KEY}?prefilled_email=${currentUser.email}`}
                size="sm"
                target="_blank"
              >
                Manage Payment Methods
              </Button>
            </>
          ) : (
            <PaymentSetupForm />
          )}
        </div>
      )}
    </div>
  );
};
