import { useForm } from "react-hook-form";
import { OfficeUserType } from "../../api-types";
import { getDirtyValues } from "../../helpers/forms/getDirtyValues";

type OfficeProfileFormType = {
  loading: boolean;
  onSubmit: any;
  currentUser: OfficeUserType;
};

export const OfficeProfileForm = ({
  currentUser,
  loading,
  onSubmit,
}: OfficeProfileFormType) => {
  const {
    formState: { errors, isDirty, dirtyFields },
    handleSubmit,
    register,
  } = useForm({
    defaultValues: currentUser,
  });
  const onSubmitDirtyValues = (data: any) => {
    onSubmit(getDirtyValues(dirtyFields, data));
  };

  return (
    <form
      className="mw-300 mx-auto"
      onSubmit={handleSubmit(onSubmitDirtyValues)}
    >
      <div className="form-group mb-4">
        <label htmlFor="officeName">Office Name</label>
        <input
          className="form-control"
          placeholder="Office Name"
          id="officeName"
          {...register("officeName", { required: true })}
        />
        {errors.officeName && <span>Office name is required</span>}
      </div>
      <div className="form-group mb-4">
        <label htmlFor="phone">Phone Number</label>
        <input
          className="form-control"
          placeholder="Phone Number"
          id="phone"
          {...register("phone", { required: true })}
        />
        {errors.phone && <span>Phone number is required</span>}
      </div>
      <div className="form-group mb-4">
        <label htmlFor="street">Street Address</label>
        <input
          className="form-control"
          placeholder="Street Address"
          id="street"
          {...register("street", { required: true })}
        />
        {errors.street && <span>Street address is required</span>}
      </div>
      <div className="form-group mb-4">
        <label htmlFor="city">City</label>
        <input
          className="form-control"
          placeholder="City"
          id="city"
          {...register("city", { required: true })}
        />
        {errors.city && <span>City is required</span>}
      </div>
      <div className="form-group mb-4">
        <label htmlFor="officeName">State</label>
        <input
          className="form-control"
          placeholder="State"
          id="state"
          {...register("state", { required: true })}
        />
        {errors.state && <span>State is required</span>}
      </div>
      <div className="form-group mb-4">
        <label htmlFor="zipCode">Zipcode</label>
        <input
          className="form-control"
          placeholder="Zipcode of office location"
          id="zipcode"
          {...register("zipCode", { required: true })}
        />
        {errors.zipCode && <span>Zipcode is required</span>}
      </div>
      <div className="form-group mb-4">
        <input
          className="form-check-input me-2"
          id="imaging"
          type="checkbox"
          {...register("imaging")}
        />
        <label htmlFor="imaging">Offers in house imaging</label>
      </div>
      <div className="form-group mb-4">
        <input
          className="form-check-input me-2"
          id="chiropracticAssistant"
          type="checkbox"
          {...register("chiropracticAssistant")}
        />
        <label htmlFor="chiropracticAssistant">
          Chiropractic assistant on staff
        </label>
      </div>
      <div className="form-group mb-4">
        <label htmlFor="therapies">Therapies offered</label>
        <input
          className="form-control"
          placeholder="Therapies offered in this office location"
          id="therapies"
          {...register("therapies")}
        />
      </div>
      <div className="mt-5">
        <button
          disabled={!isDirty || loading}
          type="submit"
          className="btn btn-primary"
        >
          Save
        </button>
      </div>
    </form>
  );
};
