import { useAuth } from "../../../../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { useCurrentUser } from "../../../../contexts/CurrentUserContext";
import { useQueryClient } from "react-query";

const NavBar = () => {
  const navigate = useNavigate();
  const { authUser, logout, setError } = useAuth();
  const { currentUser } = useCurrentUser();
  const queryClient = useQueryClient();

  async function handleLogout() {
    try {
      setError("");
      await logout();
      queryClient.invalidateQueries({ queryKey: "current-user" });

      navigate("/login");
    } catch {
      setError("Failed to logout");
    }
  }

  const confirmLogout = () => {
    if (window.confirm("Are you sure you want to log out?")) {
      handleLogout();
    }
  };

  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-light bg-light"
        style={{ height: "57px" }}
      >
        <a className="navbar-brand text-primary h1 p-2" href="/">
          CoverDoc
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
              <Link className="nav-link" to="/">
                Home <span className="sr-only"></span>
              </Link>
            </li>
            {currentUser.role === "doctor" ? (
              <li className="nav-item active">
                <Link className="nav-link" to="/posts">
                  Posts <span className="sr-only"></span>
                </Link>
              </li>
            ) : (
              <li className="nav-item active">
                <Link className="nav-link" to="/my-listings">
                  My Listings <span className="sr-only"></span>
                </Link>
              </li>
            )}
            <li className="nav-item active">
              <Link className="nav-link" to="/profile">
                Profle <span className="sr-only"></span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about">
                About Us
              </Link>
            </li>
            {!authUser && (
              <li className="nav-item">
                {/* TODO: Get the dropdown toggling */}
                <Link
                  className="nav-link"
                  to="/register"
                  // href="#" // commented this out to get rid of the compiler warning for no
                >
                  Register
                </Link>
              </li>
            )}
          </ul>
        </div>

        <div className="nav-item justify-content-end p-3">
          {authUser && currentUser ? (
            <div className="d-flex align-items-center">
              <div className="p-1">{`Logged in as: ${currentUser.email}`}</div>
              <button
                onClick={() => confirmLogout()}
                className="btn btn-secondary"
              >
                Logout
              </button>
            </div>
          ) : (
            <a className="nav-link" href="/login">
              Log In
            </a>
          )}
        </div>
      </nav>
    </>
  );
};

export default NavBar;
