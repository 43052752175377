import { Controller, useForm } from "react-hook-form";
import { getDirtyValues } from "../../helpers/forms/getDirtyValues";
import { PostType } from "../../api-types";
import DatePicker, { DateObject } from "react-multi-date-picker";
import { useEffect } from "react";

type PostFormType = {
  isLoading: boolean;
  onSubmit: any;
  post?: PostType;
};

export const PostForm = ({ isLoading, onSubmit, post }: PostFormType) => {
  const {
    control,
    formState: { errors, isDirty, dirtyFields },
    handleSubmit,
    register,
    reset,
  } = useForm({
    defaultValues: {
      ...post,
      dates: post?.dates.map((x) => x.date),
    },
  });

  useEffect(() => {
    if (post) {
      reset(
        {
          ...post,
          dates: post?.dates.map((x) => x.date),
        },
        {
          keepValues: false,
          keepDirty: false,
          keepDefaultValues: false,
        }
      );
    }
  }, [post, reset]);

  const onSubmitDirtyValues = async (data: any) => {
    await onSubmit(getDirtyValues(dirtyFields, data));
    reset({
      ...post,
      dates: post?.dates.map((x) => x.date),
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmitDirtyValues)}>
      <div className="form-group row mb-4">
        <label htmlFor="title" className="col-sm-2 col-form-label">
          Title
        </label>
        <div className="col-sm-10">
          <input
            className="form-control"
            {...register("title", { required: true })}
          />
          {errors.title && <span>This field is required</span>}
        </div>
      </div>
      <div className="form-group row mb-4">
        <label htmlFor="content" className="col-sm-2 col-form-label">
          Content
        </label>
        <div className="col-sm-10">
          <input
            className="form-control"
            {...register("content", { required: true })}
          />
          {errors.content && <span>This field is required</span>}
        </div>
      </div>
      <div className="form-group row mb-4">
        <label htmlFor="dates" className="col-sm-2 col-form-label">
          Dates
        </label>
        <div className="col-sm-10">
          <Controller
            control={control}
            name="dates"
            rules={{ required: true }}
            render={({ field: { onChange, value }, formState: { errors } }) => (
              <>
                <DatePicker
                  multiple
                  onChange={(date: DateObject[]) => {
                    onChange(date.map((x) => x.format()));
                  }}
                  value={value || ""}
                  format={"MM/DD/YYYY"}
                  render={(value, openCalendar) => (
                    <input
                      className="form-control"
                      onChange={onChange}
                      onFocus={openCalendar}
                      value={value}
                    />
                  )}
                />
                {errors.dates && <span>This field is required</span>}
              </>
            )}
          />
        </div>
      </div>
      <div className="form-group row mb-4">
        <div className="col-sm-10 offset-sm-2">
          <button
            disabled={!isDirty || isLoading}
            type="submit"
            className="btn btn-primary"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};
