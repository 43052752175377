import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { authUser, login, setError } = useAuth();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { currentUser, refetch: refetchCurrentUser } = useGetCurrentUser();

  useEffect(() => {
    if (authUser && currentUser) {
      navigate("/");
    }
  }, [authUser, currentUser, navigate]);

  async function handleFormSubmit(e: any) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await login(email, password);
      await refetchCurrentUser();
      navigate("/");
    } catch (e) {
      setError("Failed to login");
    }

    setLoading(false);
  }
  return (
    <>
      <section className="vh-100">
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col col-xl-10">
              <div className="card">
                <div className="row g-0">
                  <div className="col-md-6 col-lg-7 d-flex align-items-center">
                    <div className="card-body p-4 p-lg-5 text-black">
                      {/* TODO: move to useForm and add autocomplete for fields */}
                      <form onSubmit={handleFormSubmit}>
                        <div className="d-flex align-items-center mb-3 pb-1">
                          <span className="h1 fw-bold mb-0">Logo</span>
                        </div>
                        <h5 className="fw-normal mb-3 pb-3">
                          Sign into your account
                        </h5>
                        <div className="form-outline mb-4">
                          <input
                            id="email-address"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            className="form-control form-control-lg"
                            placeholder="Email address"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="form-outline mb-4">
                          <input
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            required
                            className="form-control form-control-lg"
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                        <div className="pt-1 mb-4">
                          <button
                            type="submit"
                            className="btn btn-dark btn-lg btn-block"
                            onSubmit={handleFormSubmit}
                            disabled={loading}
                          >
                            Login
                          </button>
                        </div>
                        <a className="small text-muted" href="#!">
                          Forgot password?
                        </a>
                        <p className="mb-5 pb-lg-2">
                          Don't have an account?&nbsp;
                          <Link to="/register">Register here</Link>
                        </p>
                        <a href="#!" className="small text-muted">
                          Terms of use.
                        </a>
                        &nbsp;
                        <a href="#!" className="small text-muted">
                          Privacy policy
                        </a>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
