import { useQuery } from "react-query";
import { getCheckoutPaymentMethods } from "../api";

export function useGetCheckoutPaymentMethods() {
  const { data, isLoading, isSuccess, error } = useQuery({
    queryKey: ["checkoutPaymentMethods"],
    queryFn: () => getCheckoutPaymentMethods(),
  });

  return { data, isLoading, isSuccess, error };
}
