import axios from "axios";
import auth from "./config/firebase";
import { API_URL } from "./constants/constants";
import {
  CheckoutCreateStripeAccountLinkType,
  CheckoutPaymentMethodsType,
  CheckoutSecretType,
  DoctorUserType,
  OfficeUserType,
  PostType,
  StripeAccountLoginLinkType,
  StripeAccountType,
} from "./api-types";

export async function getAgreementForm(): Promise<any> {
  let payloadHeader = await getAuthHeaders();

  return axios
    .get(`${API_URL}/current-user/forms/agreement`, payloadHeader)
    .then((response) => response.data);
}

export async function updateAgreementFormId(
  formId: string
): Promise<OfficeUserType | DoctorUserType> {
  let payloadHeader = await getAuthHeaders();

  return axios
    .put(
      `${API_URL}/current-user/forms/agreement`,
      { userAgreementFormId: formId },
      payloadHeader
    )
    .then((response) => response.data);
}

async function getAuthHeaders() {
  const user = auth.currentUser;
  let token;
  try {
    token = user && (await user.getIdToken());
  } catch (e) {
    // TODO: add error hanlding here
    //   setError("Failed to login");
    // if (!token) {
    //   return Promise.reject(new Error("Invalid token"));
    // }
  }

  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
}

export async function getCurrentUser(): Promise<
  OfficeUserType | DoctorUserType | null
> {
  let payloadHeader = await getAuthHeaders();

  const currentUser = axios
    .get(`${API_URL}/current-user`, payloadHeader)
    .then((response) =>
      response.data.role === "office"
        ? (response.data as OfficeUserType)
        : (response.data as DoctorUserType)
    );

  if (!currentUser) {
    return null;
  }

  return currentUser;
}

export async function getPost(postId: string | undefined): Promise<PostType> {
  let payloadHeader = await getAuthHeaders();

  return axios
    .get(`${API_URL}/posts/${postId}`, payloadHeader)
    .then((response) => response.data);
}

export async function getPosts(): Promise<PostType[]> {
  let payloadHeader = await getAuthHeaders();

  return axios
    .get(`${API_URL}/posts`, payloadHeader)
    .then((response) => response.data);
}

export async function registerUser(
  values: any
): Promise<OfficeUserType | DoctorUserType> {
  let payloadHeader = await getAuthHeaders();

  // remove password, confirmPassword, role. May could bring in lodash to be a bit cleaner?
  const { password, confirmPassword, role, ...requestBody } = values;

  return axios
    .post(`${API_URL}/register/${role}`, { ...requestBody }, payloadHeader)
    .then((response) =>
      response.data.role === "office"
        ? (response.data as OfficeUserType)
        : (response.data as DoctorUserType)
    );
}

export async function updateDoctor(
  officeId: number | undefined,
  values: any
): Promise<PostType> {
  let payloadHeader = await getAuthHeaders();

  return axios
    .put(`${API_URL}/doctors/${officeId}`, { ...values }, payloadHeader)
    .then((response) => response.data);
}

export async function updateOffice(
  officeId: number | undefined,
  values: any
): Promise<PostType> {
  let payloadHeader = await getAuthHeaders();

  return axios
    .put(`${API_URL}/offices/${officeId}`, { ...values }, payloadHeader)
    .then((response) => response.data);
}

export async function updatePost(
  postId: string | undefined,
  values: any
): Promise<PostType> {
  let payloadHeader = await getAuthHeaders();

  return axios
    .put(`${API_URL}/posts/${postId}`, { ...values }, payloadHeader)
    .then((response) => response.data);
}

export async function getCheckoutSecret(): Promise<CheckoutSecretType> {
  let payloadHeader = await getAuthHeaders();

  return axios
    .get(`${API_URL}/checkout/secret`, payloadHeader)
    .then((response) => response.data);
}

export async function getCheckoutPaymentMethods(): Promise<CheckoutPaymentMethodsType> {
  let payloadHeader = await getAuthHeaders();

  return axios
    .get(`${API_URL}/checkout/payment-methods`, payloadHeader)
    .then((response) => response.data);
}

export async function getCreateStripeAccountLink(): Promise<CheckoutCreateStripeAccountLinkType> {
  let payloadHeader = await getAuthHeaders();

  return axios
    .get(`${API_URL}/checkout/create-account-link`, payloadHeader)
    .then((response) => response.data);
}

export async function getStripeAccount(): Promise<StripeAccountType> {
  let payloadHeader = await getAuthHeaders();

  return axios
    .get(`${API_URL}/checkout/account`, payloadHeader)
    .then((response) => response.data);
}

export async function getStripeAccountLoginLink(): Promise<StripeAccountLoginLinkType> {
  let payloadHeader = await getAuthHeaders();

  return axios
    .get(`${API_URL}/checkout/account-login-link`, payloadHeader)
    .then((response) => response.data);
}
