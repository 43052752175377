import { Outlet } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useGetCheckoutSecret } from "../../hooks/useGetCheckoutSecret";
import { STRIPE_PUBLISHABLE_KEY } from "../../constants/constants";

const stripePromise = STRIPE_PUBLISHABLE_KEY
  ? loadStripe(STRIPE_PUBLISHABLE_KEY)
  : null;

export const StripeWrapper = () => {
  const { data, isLoading } = useGetCheckoutSecret();

  const options: any = {
    // passing the SetupIntent's client secret
    clientSecret: data?.clientSecret,
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <Elements stripe={stripePromise} options={options}>
          <Outlet />
        </Elements>
      )}
    </>
  );
};
