import { useQuery } from "react-query";
import { getCreateStripeAccountLink } from "../api";

export function useGetCreateStripeAccountLink() {
  const { refetch, isLoading, isSuccess, error } = useQuery({
    queryKey: ["checkoutCreateStripeAccountLink"],
    // staleTime: 1000 * 60 * 60, // 60 minutes
    queryFn: () => getCreateStripeAccountLink(),
    enabled: false,
  });

  return { refetch, isLoading, isSuccess, error };
}
