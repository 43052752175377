import { useMutation, useQueryClient } from "react-query";
import { registerUser } from "../api";

export function useRegisterUser() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (values: any) => registerUser(values),
    onSuccess: async (newUser) => {
      // ✅ update detail view directly
      await queryClient.setQueryData(["current-user"], newUser);
    },
  });
}
