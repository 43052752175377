import { useMutation, useQueryClient } from "react-query";
import auth from "../config/firebase";
import axios from "axios";
import { API_URL } from "../constants/constants";
import { PostType } from "../api-types";

export function useCreatePost() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (values: any) => {
      const user = auth.currentUser;
      const token = user && (await user.getIdToken());
      const payloadHeader = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      return axios
        .post(`${API_URL}/posts/`, { ...values }, payloadHeader)
        .then((response) => response.data);
    },
    onSuccess: async (newPost) => {
      await queryClient.setQueryData(
        ["posts"],
        (previousPosts: Array<PostType> | undefined) => {
          if (previousPosts) {
            return [...previousPosts, newPost];
          }
          return [newPost];
        }
      );

      // ✅ update detail view directly
      // queryClient.setQueryData(["posts", newPost.id], newPost);
    },
  });
}
