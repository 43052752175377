import { useMutation, useQueryClient } from "react-query";
import { updateOffice } from "../api";
import { OfficeUserType } from "../api-types";

export function useUpdateOffice(officeId: number | undefined) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (values: OfficeUserType) => updateOffice(officeId, values),
    onSuccess: async (updatedUser) => {
      await queryClient.setQueryData(["current-user"], updatedUser);
    },
  });
}
