import { useMutation, useQueryClient } from "react-query";
import { updateAgreementFormId } from "../api";

export function useUpdateUserAgreementFormId() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (formId: string) => updateAgreementFormId(formId),
    onSuccess: (currentUser) => {
      // ✅ update detail view directly
      queryClient.setQueryData(["current-user"], currentUser);
    },
  });
}
