import React from "react";
import ReactDOM from "react-dom/client";
// TODO: I think we can remove bootstrap since we are using react-bootstrap. question mark?
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
