import "./CreditCardTileStyles.scss";

type Props = {
  expiry: string;
  last4: string;
  type: string;
};

export const CreditCardTile = ({ expiry, last4, type }: Props) => (
  <div className={`credit-card ${type}`}>
    <div className="credit-card-last4">{last4}</div>
    <div className="credit-card-expiry">{expiry}</div>
  </div>
);
