import { useQuery } from "react-query";
import { getStripeAccount } from "../api";

export function useGetStripeAccount() {
  const { data, isLoading, isSuccess, error } = useQuery({
    queryKey: ["stripeAccount"],
    // staleTime: 1000 * 60 * 60, // 60 minutes
    queryFn: () => getStripeAccount(),
  });

  return { data, isLoading, isSuccess, error };
}
