import { useQuery } from "react-query";
import { getCurrentUser } from "../api";

export function useGetCurrentUser() {
  const {
    data: currentUser,
    isLoading,
    isSuccess,
    error,
    refetch,
  } = useQuery({
    queryKey: ["current-user"],
    // TODO: need to revist all these 'statetimes' and see which ones are worth having / safe having
    staleTime: 1000 * 60 * 30, // 30 minutes
    queryFn: () => getCurrentUser(),
  });

  return { currentUser, isLoading, isSuccess, error, refetch };
}
