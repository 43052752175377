import { useForm } from "react-hook-form";

export const RegisterForm = ({ loading, onSubmit }: any) => {
  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
    watch,
  } = useForm();

  const roleValue = watch("role");

  return (
    <form
      className="list-group mw-300 mx-auto"
      style={{ width: "450px" }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="">
        <div className="mb-4">
          <input
            className="form-control"
            autoComplete="email"
            type="email"
            placeholder="Email address"
            {...register("email", { required: true })}
          />
          {errors.email && <span>Email is required</span>}
        </div>
        <div className="mb-4">
          <input
            className="form-control"
            placeholder="Password"
            type="password"
            {...register("password", { required: true })}
          />
          {errors.password && <span>Password is required</span>}
        </div>
        <div className="mb-4">
          <input
            className="form-control"
            placeholder="Confirm Password"
            type="password"
            {...register("confirmPassword", { required: true })}
          />
          {errors.confirmPassword && <span>Confirm password is required</span>}
        </div>
        <div className="mb-4">
          <label htmlFor="role">
            Are you registering as a Coverage Doctor or as an Office?
          </label>
          <select
            {...register("role", { required: true })}
            className="form-control"
            defaultValue=""
            id="role"
          >
            <option disabled={true} value="">
              Please select your role
            </option>
            <option value="doctor">Doctor</option>
            <option value="office">Office</option>
          </select>
          {errors.role && <span>Role selection is required</span>}
        </div>
        {roleValue === "doctor" && (
          <div className="form-group mb-4">
            <label htmlFor="name">Name</label>
            <input
              className="form-control"
              placeholder="Name"
              id="name"
              {...register("name", { required: true })}
            />
            {errors.name && <span>Name is required</span>}
          </div>
        )}
        {roleValue === "office" && (
          <div className="form-group mb-4">
            <label htmlFor="officeName">Office Name</label>
            <input
              className="form-control"
              placeholder="Office Name"
              id="officeName"
              {...register("officeName", { required: true })}
            />
            {errors.officeName && <span>Office name is required</span>}
          </div>
        )}
        <div className="form-group mb-4">
          <label htmlFor="phone">Phone Number</label>
          <input
            className="form-control"
            placeholder="Phone Number"
            id="phone"
            {...register("phone", { required: true })}
          />
          {errors.phone && <span>Phone number is required</span>}
        </div>
        <div className="form-group mb-4">
          <label htmlFor="street">Street Address</label>
          <input
            className="form-control"
            placeholder="Street Address"
            id="street"
            {...register("street", { required: true })}
          />
          {errors.street && <span>Street address is required</span>}
        </div>
        <div className="form-group mb-4">
          <label htmlFor="city">City</label>
          <input
            className="form-control"
            placeholder="City"
            id="city"
            {...register("city", { required: true })}
          />
          {errors.city && <span>City is required</span>}
        </div>
        <div className="form-group mb-4">
          <label htmlFor="officeName">State</label>
          <input
            className="form-control"
            placeholder="State"
            id="state"
            {...register("state", { required: true })}
          />
          {errors.state && <span>State is required</span>}
        </div>
        <div className="form-group mb-4">
          <label htmlFor="zipCode">Zipcode</label>
          <input
            className="form-control"
            placeholder="Zipcode"
            id="zipcode"
            {...register("zipCode", { required: true })}
          />
          {errors.zipCode && <span>Zipcode is required</span>}
        </div>
        {roleValue === "doctor" && (
          <div className="mb-4">
            <div className="form-group mb-4">
              <label htmlFor="zipCode">
                Florida Chiropractic License Number
              </label>
              <input
                className="form-control"
                placeholder="Florida Chiropractic License Number"
                id="licenseNumber"
                {...register("licenseNumber", { required: true })}
              />
              {errors.licenseNumber && <span>License number is required</span>}
            </div>
            <div className="form-group mb-4">
              <label htmlFor="zipCode">Malpractice Insurance Number</label>
              <input
                className="form-control"
                placeholder="Malpractice Number"
                id="malpracticeNumber"
                {...register("malpracticeNumber", { required: true })}
              />
              {errors.malpracticeNumber && (
                <span>Malpractice number is required</span>
              )}
            </div>
          </div>
        )}
        {roleValue === "office" && (
          <>
            <div className="form-group mb-4">
              <input
                className="form-check-input me-2"
                id="imaging"
                type="checkbox"
                {...register("imaging")}
              />
              <label htmlFor="imaging">
                Does your office offer in house imaging?
              </label>
            </div>
            <div className="form-group mb-4">
              <input
                className="form-check-input me-2"
                id="chiropracticAssistant"
                type="checkbox"
                {...register("chiropracticAssistant")}
              />
              <label htmlFor="chiropracticAssistant">
                Does your office have a chiropractic assistant?
              </label>
            </div>
            <div className="form-group mb-4">
              <label htmlFor="therapies">Therapies</label>
              <input
                className="form-control"
                placeholder="Therapies offered (if any)"
                id="therapies"
                {...register("therapies", { required: false })}
              />
            </div>
          </>
        )}
      </div>
      <div className="mt-5">
        <button
          disabled={loading || !isDirty}
          type="submit"
          className="btn btn-primary"
        >
          Register
        </button>
      </div>
    </form>
  );
};
