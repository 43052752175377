import { useQuery } from "react-query";
import { getCheckoutSecret } from "../api";

export function useGetCheckoutSecret() {
  const { data, isLoading, isSuccess, error } = useQuery({
    queryKey: ["checkoutSecret"],
    staleTime: 1000 * 60 * 60, // 60 minutes
    queryFn: () => getCheckoutSecret(),
  });

  return { data, isLoading, isSuccess, error };
}
