import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { CurrentUserProvider } from "../contexts/CurrentUserContext";

export const RequireAuth = () => {
  const { authUser } = useAuth();
  if (!authUser) {
    // TODO: add a check here for "approved". I think we should set in place a manual approval
    // process for now. Where we check the regeistrants info (license number, malpractice number, and/or office location)
    return <Navigate to="/login" replace />;
  }

  return (
    <CurrentUserProvider>
      <Outlet />
    </CurrentUserProvider>
  );
};
