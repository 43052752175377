import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

import auth from "../config/firebase";
import { useQueryClient } from "react-query";

type Props = {
  children?: ReactNode;
};

type IAuthContext = {
  authUser: any;
  login: (email: string, password: string) => void;
  register: (email: string, password: string) => void;
  error: string;
  setError: (error: string) => void;
  logout: () => void;
};

const initialValue = {
  authUser: null,
  login: () => {},
  register: () => {},
  error: "",
  setError: () => {},
  logout: () => {},
};

const AuthContext = createContext<IAuthContext>(initialValue);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: Props) => {
  const [authUser, setAuthUser] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();

  const register = (email: string, password: string) =>
    createUserWithEmailAndPassword(auth, email, password);

  const login = (email: string, password: string) =>
    signInWithEmailAndPassword(auth, email, password);

  const logout = async () => {
    await signOut(auth);
    // Any time a user logs out we want to clear queries so they're all refetched upon next log in and do not grab from cache
    queryClient.clear();
  };

  useEffect(() => {
    // TODO: anAuthStateChanged seems to be deprecated?
    const unsubscribe = auth.onIdTokenChanged((user: any) => {
      setAuthUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value: any = {
    authUser,
    login,
    register,
    error,
    setError,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
