import "./App.css";
import "./custom.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import Layout from "./pages/layout";
import { RequireAuth } from "./hooks/useAuth";
import NotFound from "./pages/NotFound";
import Unauthorized from "./pages/Unauthorized";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "./contexts/AuthContext";
import { Register } from "./pages/Register/Register";
import ErrorMessage from "./components/layout/ErrorMessage";
import Login from "./pages/Login/Login";
import { Posts } from "./pages/Posts/Posts";
import { Post } from "./pages/Posts/Post";
import { Profile } from "./pages/Profile/Profile";
import { MyListings } from "./pages/MyListings/MyListings";
import { RequireUserAgreementForm } from "./hooks/useRequireUserAgreementForm";
import { UserAgreementForm } from "./pages/User/UserAgreementForm";
import { PaymentDetails } from "./pages/Profile/PaymentDetails";
import PaymentStatus from "./pages/Profile/PaymentStatus";
import { StripeWrapper } from "./pages/Profile/StripeWrapper";
import { RequireOfficePaymentMethod } from "./hooks/useRequireOfficePaymentMethod";

const queryClient = new QueryClient();

export default function App(): JSX.Element {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <BrowserRouter>
          <ErrorMessage />
          <Routes>
            {/* TODO: add in a "light" header for users not logged in */}
            {/* <Route path="/" element={<Layout />}> */}
            <Route path="*" element={<NotFound />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />

            <Route element={<RequireAuth />}>
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="/profile" element={<Profile />} />
                <Route
                  path="/user-agreement-form"
                  element={<UserAgreementForm />}
                />
                <Route element={<RequireUserAgreementForm />}>
                  <Route path="/posts" element={<Posts />} />
                  {/* // TODO: separate out doctor routes and office routes into own files */}
                  {/* Perhaps that blocks/reroutes based on user role? */}
                  <Route element={<RequireOfficePaymentMethod />}>
                    <Route path="/my-listings" element={<MyListings />} />
                  </Route>
                  {/* <Route path="/my-assignments" element={<MyAssignments />} /> */}
                  <Route path="/posts/:id" element={<Post />} />
                  {/* // TODO: do not let 'doctors' access the payment details page */}
                  <Route element={<StripeWrapper />}>
                    <Route
                      path="/profile/payment-details"
                      element={<PaymentDetails />}
                    />
                    <Route
                      path="/profile/payment-details/status"
                      element={<PaymentStatus />}
                    />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </QueryClientProvider>
  );
}
