import { useQuery } from "react-query";
import { getStripeAccountLoginLink } from "../api";

export function useGetStripeAccountLoginLink() {
  const { refetch, isLoading, isSuccess, error } = useQuery({
    queryKey: ["stripeAccountLoginLink"],
    // staleTime: 1000 * 60 * 60, // 60 minutes
    queryFn: () => getStripeAccountLoginLink(),
    enabled: false,
  });

  return { refetch, isLoading, isSuccess, error };
}
