import { useParams } from "react-router-dom";
import { useGetPost } from "../../hooks/useGetPost";
import { useUpdatePost } from "../../hooks/useUpdatePost";
import { PostForm } from "../../components/forms/PostForm";
import { useCurrentUser } from "../../contexts/CurrentUserContext";

export const Post = () => {
  const { id } = useParams();
  const { post, isLoading } = useGetPost(id);
  const { mutate, isLoading: isUpdatingPost } = useUpdatePost(id);
  const { currentUser } = useCurrentUser();
  let isPostCreator = false;
  let renderPostContent;

  if (!post) {
    return <div>Cannot find post.</div>;
  }

  const renderPostForm = (
    <PostForm isLoading={isUpdatingPost} onSubmit={mutate} post={post} />
  );
  const renderPostDetails = (
    <div>How do we want to display "Post" details?</div>
  );

  if (!isLoading && currentUser) {
    isPostCreator = currentUser.userId === post.office.userId;
    renderPostContent = isPostCreator ? renderPostForm : renderPostDetails;
  }

  return (
    <div className="container-fluid text-center">
      <h1 className="display-2 mb-5">Post</h1>
      <div className="list-group mw-300 mx-auto" style={{ width: "450px" }}>
        {!isLoading ? renderPostContent : "Loading..."}
      </div>
    </div>
  );
};
