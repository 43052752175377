import { useQuery } from "react-query";
import { getPosts } from "../api";

export function useGetPosts() {
  const {
    data: posts,
    isLoading,
    isSuccess,
    error,
  } = useQuery({
    queryKey: ["posts"],
    // staleTime: 1000 * 60 * 1, // 1 minute
    queryFn: () => getPosts(),
  });

  return { posts, isLoading, isSuccess, error };
}
