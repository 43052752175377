import { Link } from "react-router-dom";
import { useGetPosts } from "../../hooks/useGetPosts";
import { formatDistance } from "date-fns";
import { CoverageType, PostType } from "../../api-types";
import PostModal from "../../components/Modals/PostModal";
import { Col, Container, Row } from "react-bootstrap";

export const MyListings = () => {
  const { posts, isLoading } = useGetPosts();
  const today = new Date(Date.now());
  const getPostDate = (date: string) => new Date(date);

  return (
    <Container className="container text-center">
      <h1 className="display-2 mb-5">My Coverage Listings</h1>

      <Row>
        <Col></Col>
        <Col className="row p-10">
          <div
            className="list-group mw-800 mx-auto p-2"
            style={{ width: "450px" }}
          >
            {!isLoading &&
              posts?.map((post: PostType) => (
                // TODO: move this out to a component?
                <Link
                  to={`/posts/${post.id}`}
                  className="list-group-item list-group-item-action flex-column align-items-start"
                >
                  <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-1">{post.office.city}</h5>
                    <small className="text-muted">
                      {formatDistance(today, getPostDate(post.createdAt))} ago
                    </small>
                  </div>
                  {post.dates.map((x: CoverageType) => (
                    <p className="mb-1">{x.date}</p>
                  ))}
                  {/* <small className="text-muted">{post.office.zipCode}</small> */}
                </Link>
              ))}
          </div>
        </Col>

        <Col sm={4}>
          <div className="container height: 10px; sticky-top">
            <PostModal />
          </div>
        </Col>
      </Row>
    </Container>
  );
};
