import { useQuery } from "react-query";
import { getPost } from "../api";

export function useGetPost(postId: string | undefined) {
  const {
    data: post,
    isLoading,
    isSuccess,
    error,
  } = useQuery({
    queryKey: ["post", postId],
    // staleTime: 1000 * 60 * 1, // 1 minute
    queryFn: () => getPost(postId),
  });

  return { post, isLoading, isSuccess, error };
}
