import { Navigate, Outlet } from "react-router-dom";
import { useCurrentUser } from "../contexts/CurrentUserContext";

export const RequireUserAgreementForm = () => {
  const { currentUser } = useCurrentUser();
  if (!currentUser.userAgreementFormId) {
    return <Navigate to="/user-agreement-form" replace />;
  }

  return <Outlet />;
};
