import { useState } from "react";
import { OfficeProfileForm } from "../../components/forms/OfficeProfileForm";
import { useAuth } from "../../contexts/AuthContext";
import { useGetAgreementForm } from "../../hooks/useGetAgreementForm";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";
import { DoctorUserType, OfficeUserType } from "../../api-types";
import { useUpdateOffice } from "../../hooks/useUpdateOffice";
import { DoctorProfileForm } from "../../components/forms/DoctorProfileForm";
import { useUpdateDoctor } from "../../hooks/useUpdateDoctor";
import JotFormModal from "../../components/Modals/JotFormModal";
import { Link } from "react-router-dom";
import { useGetCreateStripeAccountLink } from "../../hooks/useGetCreateStripeAccountLink";
import { useGetStripeAccount } from "../../hooks/useGetStripeAccount";
import { Button } from "react-bootstrap";
import { useGetStripeAccountLoginLink } from "../../hooks/useGetStripeAccountLoginLink";

export const Profile = () => {
  const { currentUser, isLoading: loadingCurrentUser } = useGetCurrentUser();
  const { mutate: updateOffice } = useUpdateOffice(currentUser?.id);
  const { mutate: updateDoctor } = useUpdateDoctor(currentUser?.id);
  const { form, isLoading: loadingAgreementForm } = useGetAgreementForm();
  const { refetch: getCreateStripeAccountLink } =
    useGetCreateStripeAccountLink();
  // TODO: this get stripe account request is 404ing when logged in as an office.
  // Maybe have a separate section for doctor vs office portions that require a stripe fetch?
  const { data: stripeAccount } = useGetStripeAccount();
  const { refetch: getStripeAccountLoginLink } = useGetStripeAccountLoginLink();
  const { setError } = useAuth();
  const [loadingProfile, setLoading] = useState(false);
  const [loadingStripeDashboardLoginLink, setLoadingStripeDashboardLoginLink] =
    useState(false);
  const [loadingStripeOnboardingLink, setLoadingStripeOnboardingLink] =
    useState(false);

  async function handleFormSubmit(values: any) {
    try {
      setError("");
      setLoading(true);

      // TODO: the catching error here isn't working?
      if (currentUser?.role === "office") {
        await updateOffice(values);
      } else {
        await updateDoctor(values);
      }
    } catch (e) {
      setError("Failed to update");
    }

    setLoading(false);
  }

  const handleStripeOnboardingLink = async () => {
    setLoadingStripeOnboardingLink(true);
    const createStripeAccountLinkResponse = await getCreateStripeAccountLink();
    if (createStripeAccountLinkResponse?.data?.url) {
      window.location.href = createStripeAccountLinkResponse.data.url;
      return;
    }
    setLoadingStripeOnboardingLink(false);
  };

  const handleStripeDashboardLogin = async () => {
    setLoadingStripeDashboardLoginLink(true);
    const loginLinkResponse = await getStripeAccountLoginLink();
    if (loginLinkResponse?.data?.url) {
      window.location.href = loginLinkResponse.data.url;
      return;
    }
    setLoadingStripeDashboardLoginLink(false);
  };

  const renderOfficeProfile = () => (
    <OfficeProfileForm
      currentUser={currentUser as OfficeUserType}
      loading={loadingProfile}
      onSubmit={handleFormSubmit}
    />
  );

  const renderDoctorProfile = () => (
    <DoctorProfileForm
      currentUser={currentUser as DoctorUserType}
      loading={loadingProfile}
      onSubmit={handleFormSubmit}
    />
  );

  const renderDoctorStripeAccountDetails = () =>
    stripeAccount?.details_submitted ? (
      <>
        <h2 className="mt-5">Payout Details</h2>
        To update payout(card/bank account) details for completed contracts and
        view previous earnings/payouts/transactions, please log in to your
        Stripe Express Dashboard via the button below:
        <br />
        <Button
          className="btn btn-primary mt-3"
          disabled={loadingStripeDashboardLoginLink}
          onClick={handleStripeDashboardLogin}
        >
          Stripe Express Dashboard
        </Button>
      </>
    ) : (
      <>
        <h2 className="mt-5">Set up payout</h2>
        To receive payment for completed work, please create a Stripe account by
        clicking the button below:
        <br />
        <Button
          className="btn btn-primary mt-3"
          disabled={loadingStripeOnboardingLink}
          onClick={handleStripeOnboardingLink}
        >
          Begin Stripe Onboarding
        </Button>
      </>
    );

  const userIsOffice = currentUser?.role === "office";

  return (
    <div className="container-fluid text-center">
      <h1 className="display-2 mb-5">Profile</h1>
      <div className="container mb-6">
        <div className="row justify-content-center" style={{ gap: "75px" }}>
          <div className="col-6">
            {loadingCurrentUser ? (
              <div>Loading...</div>
            ) : userIsOffice ? (
              renderOfficeProfile()
            ) : (
              renderDoctorProfile()
            )}
          </div>

          <div className="col-6" style={{ width: "400px" }}>
            {userIsOffice ? (
              <>
                <h2 className="mt-5">Payment Methods</h2>
                <Link className="link-primary" to="/profile/payment-details">
                  View Saved Payment Methods
                </Link>
              </>
            ) : (
              renderDoctorStripeAccountDetails()
            )}
            <h2 className="mt-5">Documents</h2>
            {loadingAgreementForm ? (
              <div>Loading...</div>
            ) : form.length !== 0 ? (
              <div>
                {`You've already completed your agreement form. A copy of the agreement was sent to: ${currentUser?.email}`}
              </div>
            ) : (
              <div>
                <div>
                  {`Please complete the Coverdoc Agreement form to 
                  ${userIsOffice ? "list" : "view and accept"} coverage work.`}
                </div>
                <JotFormModal
                  url={`https://form.jotform.com/232784286471061?email=${currentUser?.email}`}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
