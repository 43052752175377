import { ReactNode, createContext, useContext } from "react";
import { useGetCurrentUser } from "../hooks/useGetCurrentUser";

type Props = {
  children?: ReactNode;
};

type ICurrentUserContext = {
  currentUser: any;
};

const initialValue = {
  currentUser: null,
};

const CurrentUserContext = createContext<ICurrentUserContext>(initialValue);

export const useCurrentUser = () => useContext(CurrentUserContext);

export const CurrentUserProvider = ({ children }: Props) => {
  const { currentUser, isLoading } = useGetCurrentUser();

  const value: any = {
    currentUser,
  };

  return (
    <CurrentUserContext.Provider value={value}>
      {!isLoading && children}
    </CurrentUserContext.Provider>
  );
};
