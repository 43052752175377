import { useAuth } from "../../contexts/AuthContext";

const ErrorMessage = () => {
  // TODO: bring the state of these out of useAuth?
  const { error, setError } = useAuth();

  return (
    error && (
      <div className="alert alert-danger" role="alert">
        <strong>Error:</strong> {error}
        <button
          type="button"
          className="btn btn-outline-danger"
          onClick={() => setError("")}
        >
          Close
        </button>
      </div>
    )
  );
};

export default ErrorMessage;
