import { Container } from "react-bootstrap";
import { useGetAgreementForm } from "../../hooks/useGetAgreementForm";
import JotFormModal from "../../components/Modals/JotFormModal";
import { useCurrentUser } from "../../contexts/CurrentUserContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUpdateUserAgreementFormId } from "../../hooks/useUpdateAgreementFormId";

export const UserAgreementForm = () => {
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();
  const { form, isLoading: loadingAgreementForm } = useGetAgreementForm();
  const { mutate: updateUserAgreementFormId } = useUpdateUserAgreementFormId();

  useEffect(() => {
    if (currentUser.userAgreementForm) {
      navigate("/posts");
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    if (form?.length > 0) {
      const handleUpdateUserAgreementId = async (formId: string) => {
        await updateUserAgreementFormId(formId);
        navigate("/");
      };

      handleUpdateUserAgreementId(form[0].id);
    }
  }, [form, navigate, updateUserAgreementFormId]);

  // TODO: hoist this 'doctor agreement form id' to env var
  const formId =
    currentUser.role === "doctor" ? "232784286471061" : "233274770554157";
  const formUrl = `https://form.jotform.com/${formId}?email=${currentUser?.email}`;

  return (
    <Container className="container text-center">
      <h1 className="display-2 mb-5">User Agreement Form</h1>
      <div
        className="list-group mw-800 mx-auto p-2 m-2"
        style={{ width: "450px" }}
      >
        {loadingAgreementForm ? (
          <div>Loading...</div>
        ) : form.length !== 0 ? (
          <div>
            {`You've already completed your agreement form. A copy of the agreement was sent to: ${currentUser?.email}`}
          </div>
        ) : (
          <div>
            <div>
              Please read and sign the Coverdoc Agreement form to view and
              accept coverage work.
            </div>
            <JotFormModal url={formUrl} />
          </div>
        )}
      </div>
    </Container>
  );
};
