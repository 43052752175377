import { useQuery } from "react-query";
import { getAgreementForm } from "../api";

export function useGetAgreementForm() {
  const {
    data: form,
    isLoading,
    isSuccess,
    error,
  } = useQuery({
    queryKey: ["agreement-form"],
    // staleTime: 1000 * 60 * 1, // 1 minute
    queryFn: () => getAgreementForm(),
  });

  return { form, isLoading, isSuccess, error };
}
