import { Navigate, Outlet } from "react-router-dom";
import { useGetCheckoutPaymentMethods } from "./useGetCheckoutPaymentMethods";

export const RequireOfficePaymentMethod = () => {
  const { data: paymentMethods } = useGetCheckoutPaymentMethods();

  if (paymentMethods && paymentMethods.length === 0) {
    return <Navigate to="/profile/payment-details" replace />;
  }

  return <Outlet />;
};
