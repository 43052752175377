import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { PostForm } from "../forms/PostForm";
import { useCreatePost } from "../../hooks/useCreatePost";
import { useState } from "react";

function PostModal() {
  const { mutate: create, isLoading: isCreatingPost } = useCreatePost();

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleSubmit = async (values: any) => {
    await create(values);
    setShow(false);
  };

  return (
    <div className="modal" style={{ display: "block", position: "initial" }}>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "10vh" }}
      >
        <Button variant="primary" onClick={handleShow}>
          Create New Listing
        </Button>
      </div>
      <div>(a listing can be for more than one day of covereage)</div>

      <Modal centered show={show}>
        <Modal.Header>
          <Modal.Title>Create Post</Modal.Title>
          <Button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          ></Button>
        </Modal.Header>

        <Modal.Body>
          <PostForm isLoading={isCreatingPost} onSubmit={handleSubmit} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="btn btn-danger"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PostModal;
